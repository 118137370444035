@font-face {
  font-family: "IM Fell English";
  font-style: normal;
  font-weight: 400;
  src: local("IM FELL English Roman"), local("IM_FELL_English_Roman"),
    url(./fonts-rpt/xwIisCqGFi8pff-oa9uSVMyhYfqH3mbn79-t0w0Xep4.woff)
      format("woff"),
    url(./fonts-rpt/IMFeENrm28P.ttf) format("truetype");
}

@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  src: local("Lora"), local("Lora-Regular"),
    url(./fonts-rpt/5-AYViExptypIdFoLKAxTA.woff) format("woff"),
    url(./fonts-rpt/Lora-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  src: local("Lora Bold"), local("Lora-Bold"),
    url(./fonts-rpt/XpaepWHcooQHSRnzoohUng.woff) format("woff"),
    url(./fonts-rpt/Lora-Bold.ttf) format("truetype");
}

table {
  border-collapse: separate;
  border-spacing: 4px 8px;
}

table td {
  vertical-align: top;
}

body {
  font-family: "Lora" !important;
  font-size: 16px;
}

.panel {
  background-color: #f2f2f2;
}

.cl-nav-bar {
  background-color: black;
  color: white;
  padding: 10px 0;
}

.cl-nav-bar a {
  color: white;
  margin: 0 10px;
}

.intro {
  background-color: inherit;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
}

.intro > a > img {
  max-width: 100%;
}

h1.stream-name {
  font-size: 48px;
}

.tag-cloud {
  font-size: smaller;
}

.tag-cloud > a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "IM Fell English";
}

ol.entries {
  list-style: none;
  padding-left: 0px;
}

@media screen and (min-width: 768px) {
  /* SM */
}

@media screen and (min-width: 992px) {
  /* MD */
}

@media screen and (min-width: 1200px) {
  /* LG */
}

@media screen and (min-width: 768px) and (max-width: 1640px) {
  ol.entries li.panel {
    margin-left: calc(220px);
  }
}

.entry-meta {
  float: left;
  margin-left: -220px;
  width: 200px;
  text-align: right;
}

.entry-meta-pos {
  background: #b01f0c;
  border-radius: 100%;
  text-align: center;
  color: white;
  width: 1.5em;
  height: 1.5em;
  padding-top: 0;
  font-size: 30px;
  font-family: "IM Fell English";
  margin-left: 160px;
  margin-bottom: 20px;
}

.entry-meta-download {
  background-image: url(img/noun_Download_2756333.png);
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 150px;
  margin-bottom: 10px;
  width: 64px;
  cursor: pointer;
}

.entry-meta-right {
  float: right;
  margin-right: -220px;
  width: 200px;
  text-align: left;
}

.entry-meta-tags {
  font-size: smaller;
}

@media screen and (min-width: 768px) and (max-width: 1640px) {
  .entry-meta-right {
    float: left;
    margin-top: 6em;
    margin-left: -220px;
    width: 200px;
    text-align: right;
  }
}

@media screen and (max-width: 767px) {
  .entry-meta {
    float: unset;
    margin-left: unset;
    width: unset;
    text-align: right;
    margin: 5px;
    min-height: 2.5em;
  }

  .entry-meta-pos {
    float: left;
    margin-left: unset;
    margin-bottom: 10px;
  }

  .entry-meta-right {
    float: unset;
    margin-right: unset;
    width: unset;
    text-align: right;
    margin: 5px;
    min-height: 2.5em;
  }

  .entry-meta-tags {
    margin-bottom: 10px;
  }
}

.panel h1,
.panel h2,
.panel h3,
.panel h4,
.panel h5,
.panel h6 {
  margin: 0;
}

a {
  color: #b01f0c;
}

a[target="_blank"] {
  background-color: #e4e4e4;
  border-radius: 3px;
  padding: 3px 6px;
  color: black;
  margin: 0;
}

.mermaid * {
  font-weight: normal !important;
  font-size: unset;
}
